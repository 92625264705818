import React from "react";
import styled from "styled-components";
import { Field, getIn, connect } from "formik";
import Section from "../containers/Section";
import AmountButton from "../components/Buttons/AmountButton";
import { ErrorMessage } from "../utilities/utilities";

const Div = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormGroup = styled.div`
  maginleft: 1.5rem;
  paddingtop: 10px;
`;

const P = styled.p`
  margin-left: 1.5rem;
`;

const UnitInfo = (props) => {
  const errors = getIn(props.formik.errors);
  const touched = getIn(props.formik.touched);

  return (
    <React.Fragment>
      <div>
        <Section>
          <FormGroup>
            <P>Number of Beds/ Baths*</P>
            <Field name="unitBedsBaths" component="select">
              <option value=""> Select an option </option>
              <option value="1 Bed/ 1 Bath"> 1 Bed/ 1 Bath </option>
              <option value="2 Bed/ 1 Bath"> 2 Bed/ 1 Bath </option>
              <option value="2 Bed/ 1.5 Bath"> 2 Bed/ 1.5 Bath </option>
              <option value="3 Bed/ 1 Bath"> 3 Bed/ 1 Bath </option>
              <option value="3 Bed/ 1.5 Bath"> 3 Bed/ 1.5 Bath </option>
              <option value="3 Bed/ 2 Bath"> 3 Bed/ 2 Bath </option>
              <option value="3 Bed/ 2.5 Bath"> 3 Bed/ 2.5 Bath </option>
              <option value="4 Bed/ 1 Bath"> 4 Bed/ 1 Bath </option>
              <option value="4 Bed/ 1.5 Bath"> 4 Bed/ 1.5 Bath </option>
              <option value="4 Bed/ 2 Bath"> 4 Bed/ 2 Bath </option>
              <option value="4 Bed/ 2.5 Bath"> 4 Bed/ 2.5 Bath </option>
              <option value="4 Bed/ 3 Bath"> 4 Bed/ 3 Bath </option>
              <option value="4 Bed/ 3.5 Bath"> 4 Bed/ 3.5 Bath </option>
              <option value="4 Bed/ 4 Bath"> 4 Bed/ 4 Bath </option>
              <option value="5 Bed/ 1 Bath"> 5 Bed/ 1 Bath </option>
              <option value="5 Bed/ 1.5 Bath"> 5 Bed/ 1.5 Bath </option>
              <option value="5 Bed/ 2 Bath"> 5 Bed/ 2 Bath </option>
              <option value="5 Bed/ 2.5 Bath"> 5 Bed/ 2.5 Bath </option>
              <option value="5 Bed/ 3 Bath"> 5 Bed/ 3 Bath </option>
              <option value="5 Bed/ 3.5 Bath"> 5 Bed/ 3.5 Bath </option>
              <option value="5 Bed/ 4 Bath"> 5 Bed/ 4 Bath </option>
              <option value="5 Bed/ 4.5 Bath"> 5 Bed/ 4.5 Bath </option>
              <option value="5 Bed/ 5 Bath"> 5 Bed/ 5 Bath </option>
            </Field>
            {touched.unitBedsBaths && errors.unitBedsBaths && (
              <ErrorMessage>{errors.unitBedsBaths}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <P>Unit Level*</P>
            <Field name="unitLevels" component="select">
              <option value="">Select an option</option>
              <option value="Ground Floor">Ground Floor</option>
              <option value="First Floor">First Floor</option>
              <option value="Level 2 or above">Level 2 or above</option>
            </Field>
            {touched.unitLevels && errors.unitLevels && (
              <ErrorMessage>{errors.unitLevels}</ErrorMessage>
            )}
          </FormGroup>
        </Section>
      </div>
      <div>
        <Section
          title="Does the home have any additional living spaces?"
          style={{
            paddingBottom: 0,
          }}
          info="Our deep cleaning package includes the cleaning of one (1) living and one
        (1) dining space. If you have any additional living spaces that require
        cleaning, please indicate the number of rooms below including external
        office spaces, converted garage, playrooms, gyms etc."
        >
          <Div>
            <Field
              name="livingSpacesUnit"
              component={AmountButton}
              type="button"
              id="unitLivingSpaces"
              label=""
              amountFloor={0}
              amountCeiling={5}
              wrapperWidth="20rem"
              buttonSize="8.5rem"
              style={{ alignSelf: "center" }}
            />
          </Div>
        </Section>
      </div>
    </React.Fragment>
  );
};

export default connect(UnitInfo);
