import React, { useEffect, useRef } from "react";
import { Field, connect, getIn } from "formik";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import FadeIn from "react-fade-in";

// import { scrollToSection } from "../utilities/utilities";

import LaundryServices from "../formSections/LaundryServices";
import OptionalExtras from "../formSections/OptionalExtras";
import Extras from "../formSections/Extras";
import TimeAndDate from "../formSections/TimeAndDate";
import { ErrorMessage } from "../utilities/utilities";

import SelectableOption from "../components/SelectableOptions";
// import AmountButton from "../components/Buttons/AmountButton";

import "../styles/customStyles.css";
// import styled from "styled-components";

// const Div = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// `;

const Page4 = (props) => {
  /* connect formik props to component */
  const values = getIn(props.formik.values);
  const touched = getIn(props.formik.touched);
  const errors = getIn(props.formik.errors);
  const setFieldValue = getIn(props.formik.setFieldValue);

  // Section References
  const howOften = useRef(null);
  const housekeeping = useRef(null);
  const laundry = useRef(null);
  const laundryServices = useRef(null);
  const optionalExtras = useRef(null);
  const extras = useRef(null);
  const timeAndDate = useRef(null);

  useEffect(() => {
    if (
      values.laundry === "NO" &&
      values.changeBedlinen !== ("" || false || null)
    ) {
      setFieldValue("changeBedlinen", "");
    }
    if (
      values.laundry === "NO" &&
      values.washBedlinen !== ("" || false || null)
    ) {
      setFieldValue("washBedlinen", "");
    }
    if (
      values.laundry === "NO" &&
      values.washClothes !== ("" || false || null)
    ) {
      setFieldValue("washClothes", "");
    }
    if (values.laundry === "NO" && values.ironFold !== ("" || false || null)) {
      setFieldValue("ironFold", "");
    }
    if (values.laundry === "NO" && values.bedlinens !== "") {
      setFieldValue("bedlinens", "");
    }
    if (values.laundry === "NO" && values.linenLoads !== "") {
      setFieldValue("linenLoads", "");
    }
    if (values.laundry === "NO" && values.clothesLoads !== "") {
      setFieldValue("clothesLoads", "");
    }
    if (values.laundry === "NO" && values.ironLoads !== "") {
      setFieldValue("ironLoads", "");
    }
  }, [
    setFieldValue,
    values.bedlinens,
    values.changeBedlinen,
    values.clothesLoads,
    values.ironFold,
    values.ironLoads,
    values.laundry,
    values.linenLoads,
    values.washBedlinen,
    values.washClothes,
  ]);

  return (
    <UberSection title="SERVICES REQUIRED">
      <div ref={howOften}>
        <FadeIn>
          <Section title="How often should the cleaners come?">
            <Field name="howOften" component="select">
              <option value="">Select an option</option>
              <option value="Bi-weekly">Bi-weekly</option>
              <option value="Weekly">Weekly</option>
              <option value="Fortnightly">Fortnightly</option>
              <option value="Monthly">Monthly</option>
              <option value="Once Only">Once Only</option>
            </Field>
            <br />
          </Section>
        </FadeIn>
      </div>
      <div ref={housekeeping}>
        <FadeIn>
          <Section
            title="Are housekeeping services required?"
            info="If you are unable to prepare your home for your cleaner,
            or you simply need another pair of hands to help get your home
            ready and in tip top shape, then our housekeeping services are
            perfect for you."
          >
            <Field
              name="housekeeping"
              component={SelectableOption}
              value="YES"
              type="radio"
              label="YES"
              id="housekeepingYes"
            />
            <Field
              name="housekeeping"
              component={SelectableOption}
              value="NO"
              type="radio"
              label="NO"
              id="housekeepingNo"
            />
            <Field
              name="housekeeping"
              component={SelectableOption}
              value="NOT SURE"
              type="radio"
              label="NOT SURE"
              id="housekeepingNotSure"
            />
          </Section>
        </FadeIn>
      </div>
      <div ref={laundry}>
        <FadeIn>
          <Section title="Are any in-home laundry services required?*">
            <Field
              name="laundry"
              component={SelectableOption}
              value="YES"
              type="radio"
              label="YES PLEASE"
              id="laundry-yes"
            />
            <Field
              name="laundry"
              component={SelectableOption}
              value="NO"
              type="radio"
              label="NO THANKS"
              id="laundry-no"
            />
            {touched.laundry && errors.laundry && (
              <ErrorMessage>{errors.laundry}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      </div>
      <div ref={laundryServices}>
        {values.laundry === "YES" && <LaundryServices />}
      </div>
      <div ref={optionalExtras}>
        <OptionalExtras />
      </div>
      <div ref={extras}>
        <Extras />
      </div>
      <div ref={timeAndDate}>
        <TimeAndDate />
      </div>
    </UberSection>
  );
};

export default connect(Page4);
