import * as Yup from "yup";

const page4Schema = Yup.object().shape({
  howOften: Yup.string().required(
    "This is required. Please choose from the options."
  ),
  housekeeping: Yup.string().required(
    "This is required. Please pick yes or no."
  ),
  laundry: Yup.string().required("This is required. Please pick yes or no."),
  endDate: Yup.string()
    .required("This is required. Please enter an end date.")
    .nullable(),
});

export default page4Schema;
