import React from "react";
import styled from "styled-components/macro";

const Progress = styled.ul`
  display: inline-flex;
  align-items: center;
  counter-reset: step;
  padding: 0 0 50px 30px;
  margin: 0;
  width: 90%;
  color: DarkCyan;
  font-family: $font;
  list-style: none;
  @media (max-width: 768px) {
    width: 90vw;
    margin: 0 auto;
    padding: 0 0 50px 0;
  }
`;

const Circle = styled.li`
  width: 50px;
  height: 50px;
  border: ${(props) =>
    props.currentStep >= Number(props.index)
      ? "5px solid #76ff03"
      : "5px solid DarkCyan"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  &:before {
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 2em;
    color: ${(props) =>
      props.currentStep >= Number(props.index) + 1 ? "#76ff03" : "#f3f3f3"};
    margin-top: 0.3em;
  }

  &:after {
    counter-increment: step;
    content: "Step " counter(step);
    margin-top: 1.2rem;
  }
`;

const Bar = styled.li`
  flex: 1;
  height: 5px;
  background-color: ${(props) =>
    props.currentStep >= Number(props.index) ? "#76ff03" : "DarkCyan"};
`;

const ProgressBar = (props) => {
  return (
    <Progress>
      <Circle index="0" currentStep={props.currentStep} />
      <Bar index="0" currentStep={props.currentStep} />
      <Circle index="1" currentStep={props.currentStep} />
      <Bar index="1" currentStep={props.currentStep} />
      <Circle index="2" currentStep={props.currentStep} />
      <Bar index="2" currentStep={props.currentStep} />
      <Circle index="3" currentStep={props.currentStep} />
      <Bar index="3" currentStep={props.currentStep} />
      <Circle index="4" currentStep={props.currentStep} />
    </Progress>
  );
};

export default ProgressBar;
