import React from "react";
import styled from "styled-components/macro";

const P = styled.p`
  font-weight: 300;
  font-style: normal;
  font-size: 1.6rem;
  letter-spacing: 0px;
  line-height: 1.6em;
  color: white !important;
  background-color: #ed1968 !important;
  padding: 16px;
  text-align: center;
  margin: 0;
`;

const UberSection = (props) => (
  <>
    <P>
      <strong>{props.title}</strong>
    </P>
    <br />
    {props.children}
  </>
);

export default UberSection;
