import React, { useEffect } from "react";
import { Field, connect, getIn } from "formik";
import Section from "../containers/Section";
import ExtrasHeader from "../components/cards/ExtrasHeader";
import AmountButton from "../components/Buttons/AmountButton";

import { ExtrasWrapper } from "../styles/ButtonStyles";

function LaundryServices(props) {
  const values = getIn(props.formik.values);
  const setFieldValue = getIn(props.formik.setFieldValue);

  useEffect(() => {
    if (values.changeBedlinen === false && values.bedlinens !== "") {
      setFieldValue("bedlinens", "");
    }
    if (values.washBedlinen === false && values.linenLoads !== "") {
      setFieldValue("linenLoads", "");
    }
    if (values.washClothes === false && values.clothesLoads !== "") {
      setFieldValue("clothesLoads", "");
    }
    if (values.ironFold === false && values.ironLoads !== "") {
      setFieldValue("ironLoads", "");
    }
  }, [
    setFieldValue,
    values.changeBedlinen,
    values.bedlinens,
    values.washBedlinen,
    values.linenLoads,
    values.washClothes,
    values.clothesLoads,
    values.ironFold,
    values.ironLoads,
  ]);

  return (
    <Section>
      <ExtrasWrapper>
        <Field
          name="changeBedlinen"
          component={ExtrasHeader}
          id="change-bedlinen"
          label="Change Bedlinen"
          icon="icon-linen"
        />
        {values.changeBedlinen && (
          <Field
            name="bedlinens"
            component={AmountButton}
            id="bedlinens"
            label="Total Beds"
            amountFloor={0}
            amountCeiling={20}
            wrapperWidth="8.5rem"
            buttonSize="7.75rem"
            buttonPadding="0.8rem 0"
          />
        )}
      </ExtrasWrapper>
      <ExtrasWrapper>
        <Field
          name="washBedlinen"
          component={ExtrasHeader}
          id="wash-bedlinen"
          label="Wash Linens"
          icon="icon-washing"
        />
        {values.washBedlinen && (
          <Field
            name="linenLoads"
            component={AmountButton}
            id="linen-loads"
            label="Total Baskets"
            amountFloor={0}
            amountCeiling={20}
            wrapperWidth="8.5rem"
            buttonSize="7.75rem"
            buttonPadding="0.8rem 0"
          />
        )}
      </ExtrasWrapper>
      <ExtrasWrapper>
        <Field
          name="washClothes"
          component={ExtrasHeader}
          id="wash-clothes"
          label="Wash Clothes"
          icon="icon-clothes"
        />
        {values.washClothes && (
          <Field
            name="clothesLoads"
            component={AmountButton}
            id="clothes-loads"
            label="Total Baskets"
            amountFloor={0}
            amountCeiling={20}
            wrapperWidth="8.5rem"
            buttonSize="7.75rem"
            buttonPadding="0.8rem 0"
          />
        )}
      </ExtrasWrapper>
      <ExtrasWrapper>
        <Field
          name="ironFold"
          component={ExtrasHeader}
          id="ironFold"
          label="Iron & Fold"
          icon="icon-ironing"
        />
        {values.ironFold && (
          <Field
            name="ironLoads"
            component={AmountButton}
            id="ironLoads"
            label="Total Baskets"
            amountFloor={0}
            amountCeiling={20}
            wrapperWidth="8.5rem"
            buttonSize="7.75rem"
            buttonPadding="0.8rem 0"
          />
        )}
      </ExtrasWrapper>
    </Section>
  );
}

export default connect(LaundryServices);
