// Contact Details

import React, { useEffect } from "react";
import { Field, connect, getIn } from "formik";
import FadeIn from "react-fade-in";
import { scrollToSection } from "../utilities/utilities";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import { Label, Input } from "../styles/InputStyles";
import ParticipantAddress from "./ParticipantAddress";
import Datepicker from "../components/Datepicker/Datepicker";
import SelectableOption from "../components/SelectableOptions";
import NomineeDetails from "./NomineeDetails";
import { EmDiv } from "../styles/InputStyles";
import { ErrorMessage } from "../utilities/utilities";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../styles/customStyles.css";

const ContactDetails = (props) => {
  const values = getIn(props.formik.values);
  const errors = getIn(props.formik.errors);
  const setFieldValue = getIn(props.formik.setFieldValue);
  const touched = getIn(props.formik.touched);

  useEffect(() => {
    if (values.hasNominee !== "YES") {
      if (values.nomineeFName !== "") {
        setFieldValue("nomineeFName", "");
      }
      if (values.nomineeLName !== "") {
        setFieldValue("nomineeLName", "");
      }
      if (values.nomineePhone !== "") {
        setFieldValue("nomineePhone", "");
      }
      if (values.nomineeEmail !== "") {
        setFieldValue("nomineeEmail", "");
      }
    }
  }, [
    setFieldValue,
    values.hasNominee,
    values.nomineeFName,
    values.nomineeLName,
    values.nomineePhone,
    values.nomineeEmail,
  ]);

  const p1errors =
    errors.participantFName ||
    errors.participantLName ||
    errors.participantNDISRef ||
    errors.participantMobile ||
    errors.participantEmail ||
    errors.participantDOB ||
    errors.preferredContactMethod ||
    !values.hasNominee ||
    (values.hasNominee === "YES" &&
      (errors.nomineeFName ||
        errors.nomineeLName ||
        errors.nomineeEmail ||
        errors.nomineePhone))
      ? values.hasNominee === "NO"
        ? false
        : true
      : false;

  return (
    <>
      <FadeIn>
        <UberSection title="PARTICIPANT DETAILS">
          <FadeIn>
            <Section title="Does The Participant Have a Nominee or Support Coordinator?*">
              <Field
                name="hasNominee"
                component={SelectableOption}
                value="YES"
                type="radio"
                label="YES"
                id="nom-yes"
                onClick={() => scrollToSection(props.refs[9])}
              />
              <Field
                name="hasNominee"
                component={SelectableOption}
                value="NO"
                type="radio"
                label="NO"
                id="nom-no"
                onClick={() => {
                  scrollToSection(props.refs[9]);
                }}
              />
            </Section>
          </FadeIn>
          <FadeIn>
            <div ref={props.refs[9]}>
              {values.hasNominee === "YES" && (
                <FadeIn>
                  <NomineeDetails />
                </FadeIn>
              )}
            </div>
          </FadeIn>
          <FadeIn>
            <Section title=" ">
              <Field name="participantFName" id="participantFName">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Participant First Name*:</Label>
                    <Input
                      {...field}
                      type="text"
                      value={field.value}
                      touched={touched}
                      errors={errors}
                    />
                  </>
                )}
              </Field>
              {touched.participantFName && errors.participantFName && (
                <ErrorMessage>{errors.participantFName}</ErrorMessage>
              )}
              <Field name="participantLName" id="participantLName">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Participant Last Name*:</Label>
                    <Input {...field} />
                  </>
                )}
              </Field>
              {touched.participantLName && errors.participantLName && (
                <ErrorMessage>{errors.participantLName}</ErrorMessage>
              )}
              <Field name="participantNDISRef" id="participantNDISRef">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>
                      Participant NDIS Reference No*:
                    </Label>
                    <Input type="number" {...field} />
                  </>
                )}
              </Field>
              {touched.participantNDISRef && errors.participantNDISRef && (
                <ErrorMessage>{errors.participantNDISRef}</ErrorMessage>
              )}
              <Field name="participantMobile" id="participantMobile">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>
                      {`Participant Mobile Number${
                        values.hasNominee === "NO" ? "*" : ""
                      }:`}
                    </Label>
                    <PhoneInput
                      countries={["AU"]}
                      defaultCountry="AU"
                      value={field.value}
                      onChange={(phoneNumber) => {
                        setFieldValue(field.name, phoneNumber);
                        return !null ? phoneNumber : null;
                      }}
                      name="participantMobile"
                    />
                  </>
                )}
              </Field>
              {touched.participantMobile && errors.participantMobile && (
                <ErrorMessage>{errors.participantMobile}</ErrorMessage>
              )}
              <br />
              <br />
              <Field name="participantPhone" id="participantPhone">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Participant Home Phone:</Label>
                    <PhoneInput
                      countries={["AU"]}
                      defaultCountry="AU"
                      value={field.value}
                      onChange={(phoneNumber) => {
                        setFieldValue(field.name, phoneNumber);
                        return !null ? phoneNumber : null;
                      }}
                      name="participantPhone"
                    />
                  </>
                )}
              </Field>
              <Field name="participantEmail" id="participantEmail">
                {({ field }) => (
                  <>
                    <Label htmlFor={field.id}>Participant Email:</Label>
                    <Input {...field} type="email" />
                  </>
                )}
              </Field>
              <div>
                <p style={{ marginLeft: "1.5rem", paddingBottom: 0 }}>
                  Participant Date of Birth:*
                </p>
                <Datepicker
                  name="participantDOB"
                  onClick={() => scrollToSection(props.refs[7])}
                  className="date"
                />
              </div>
              {touched.participantDOB && errors.participantDOB && (
                <ErrorMessage>{errors.participantDOB}</ErrorMessage>
              )}
            </Section>
          </FadeIn>
          <FadeIn>
            <ParticipantAddress />
          </FadeIn>
          <FadeIn>
            <Section
              title="Preferred contact method*"
              info="(Please let us know how you'd like us to contact you)"
            >
              <Field
                name="preferredContactMethod"
                component={SelectableOption}
                value="Email"
                type="radio"
                label="EMAIL"
              />
              <Field
                name="preferredContactMethod"
                component={SelectableOption}
                value="Phone"
                type="radio"
                label="PHONE"
              />
              <Field
                name="preferredContactMethod"
                component={SelectableOption}
                value="Via Nominee/Plan Manager"
                type="radio"
                label="NOMINEE/PLAN MANAGER"
              />
            </Section>
          </FadeIn>
          {p1errors && (
            <EmDiv role="alert">
              <em style={{ fontSize: 14 }}>
                The Fields marked with * are required. You will need to fill
                them before you can continue.
              </em>
            </EmDiv>
          )}
        </UberSection>
      </FadeIn>
    </>
  );
};

export default connect(ContactDetails);
