import React, { useEffect } from "react";
import { Field, connect, getIn } from "formik";
import Section from "../containers/Section";
import OptionalExtra from "../components/cards/OptionalExtra";
import FadeIn from "react-fade-in";
import "../styles/customStyles.css";
import styled from "styled-components";

const Div = styled.div`
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const OptionalExtras = (props) => {
  const values = getIn(props.formik.values);
  const setFieldValue = getIn(props.formik.setFieldValue);

  useEffect(() => {
    /* resets component values if their parent's value is null or empty */

    if (
      values.dustBookshelves === false &&
      values.dustBookshelvesPerYr !== ""
    ) {
      setFieldValue("dustBookshelvesPerYr", "");
    }

    if (
      values.blindsAndShutters === false &&
      values.blindsAndShuttersPerYr !== ""
    ) {
      setFieldValue("rangehdCleansPerYr", "");
    }

    if (values.framesHangings === false && values.framesHangingsPerYr !== "") {
      setFieldValue("framesHangingsPerYr", "");
    }

    if (values.flyscreens === false && values.flyscreensPerYr !== "") {
      setFieldValue("flyscreensPerYr", "");
    }

    if (values.flyscreens === false && values.doorScreensNum !== "") {
      setFieldValue("doorScreensNum", "");
    }

    if (values.flyscreens === false && values.winScreensNum !== "") {
      setFieldValue("winScreensNum", "");
    }
    if (values.balconyPatio === false && values.balconyPatioPerYr !== "") {
      setFieldValue("balconyPatioPerYr", "");
    }
    if (values.entrancesPaths === false && values.entrancesPathsPerYr !== "") {
      setFieldValue("entrancesPathsPerYr", "");
    }
    if (values.floorRugs === false && values.floorRugsPerYr !== "") {
      setFieldValue("floorRugsPerYr", "");
    }
  }, [
    setFieldValue,
    values.dustBookshelves,
    values.dustBookshelvesPerYr,
    values.blindsAndShutters,
    values.blindsAndShuttersPerYr,
    values.framesHangings,
    values.framesHangingsPerYr,
    values.flyscreens,
    values.flyscreensPerYr,
    values.winScreensNum,
    values.doorScreensNum,
    values.balconyPatio,
    values.balconyPatioPerYr,
    values.entrancesPaths,
    values.entrancesPathsPerYr,
    values.floorRugs,
    values.floorRugsPerYr,
  ]);

  return (
    <FadeIn>
      <Section title="Please select any optional extras you would like added to your bookings">
        <Field
          name="dustBookshelves"
          component={OptionalExtra}
          id="bookshelves"
          label="Dust Bookshelves"
          icon="icon-bookshelf"
        />
        <Field
          name="framesHangings"
          component={OptionalExtra}
          id="pic-frames-wall-hang"
          label="Dust Frames/Hangings"
          icon="icon-frames"
        />
        <Field
          name="flyscreens"
          component={OptionalExtra}
          id="flyscreens"
          label="Wipe Down Fly Screens"
          icon="icon-flyscreens"
        />
        <Field
          name="balconyPatio"
          component={OptionalExtra}
          id="balcony-patio"
          label="Sweep/Wash Balcony & Patio Floors"
          icon="icon-balcony"
        />
        <Field
          name="entrancesPaths"
          component={OptionalExtra}
          id="entrance-paths"
          label="Sweep Entrances & Paths"
          icon="icon-pathways"
        />
        <Field
          name="floorRugs"
          component={OptionalExtra}
          id="floor-rugs"
          label="Beat Out Floor Rugs"
          icon="icon-rugs"
        />
      </Section>
      {values.flyscreens && (
        <FadeIn>
          <Section title="You selected 'Wipe Flyscreens' from Optional Extras. We just need some more info.">
            <Div>
              <p>Number of window flyscreens:</p>
              <Field name="winScreensNum" component="select">
                <option value="No Flyscreens">No Flyscreens</option>
                <option value="1 - 4 Windows">1 - 4 Windows</option>
                <option value="5 - 8 Windows">5 - 8 Windows</option>
                <option value="9 - 12 Windows">9 - 12 Windows</option>
                <option value="13 - 16 Windows">13 - 16 Windows</option>
                <option value="17 - 20 Windows">17 - 20 Windows</option>
              </Field>
            </Div>
            <Div>
              <p>Number of door flyscreens:</p>
              <Field name="doorScreensNum" component="select">
                <option value="No Flyscreens">No Door Screens</option>
                <option value="1 - 2 Door Screens">1 - 2 Door Screens</option>
                <option value="3 - 4 Door Screens">3 - 4 Door Screens</option>
                <option value="5 - 6 Door Screens">5 - 6 Door Screens</option>
                <option value="7 - 8 Door Screens">7 - 8 Door Screens</option>
                <option value="9 - 10 Door Screens">9 - 10 Door Screens</option>
              </Field>
            </Div>
          </Section>
        </FadeIn>
      )}
    </FadeIn>
  );
};

export default connect(OptionalExtras);
