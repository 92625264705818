// App.js
import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import SidePanel from "./components/SidePanel/SidePanel";
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";
import Page5 from "./pages/Page5";
import Page1Schema from "./utilities/yup/page1Schema";
import Page2Schema from "./utilities/yup/page2Schema";
import Page3Schema from "./utilities/yup/page3Schema";
import Page4Schema from "./utilities/yup/page4Schema";
import Page5Schema from "./utilities/yup/page5Schema";
import Banner from "./assets/images/Header_Booking-Request-Form.png";
import ProgressBar from "./components/ProgressBar";
import handleSubmit from "./utilities/handleSubmit";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import loadingGif from "./assets/images/sista-spinner.gif";

var config = {
  apiKey: "AIzaSyBIScnqDKFukGhVt8bq4cy6TlYgs6bHouU",
  authDomain: "https://bookings.sistability.com.au/",
  projectId: "sistability-booking-form",
};

firebase.initializeApp(config);

// if (window.location.hostname === "localhost") {
//   console.log("localhost detected!");
//   db.useEmulator("localhost", 8080);
// }

// styled Components
const Body = styled.div`
  width: 100vw;
  background: #f3f3f3;
`;
const PageWrapper = styled.div`
  width: 100vw;
  margin: 0 auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  @media (min-width: 768px) {
    width: 56rem;
  }
`;

const PageBanner = styled.div`
  @media (min-width: 768px) {
    height: 400px;
    width: 100vw;
    background: url(${Banner});
  }
`;

const FormTitle = styled.h1`
  color: DarkCyan;
  width: 90vw;
  text-align: center;
  padding: 2.5rem 0;
  margin: 0 auto;
  display: block;
  font-weight: 200;
  font-size: 3rem;
`;

// ${tw`w-108 md:w-132 flex-none min-h-screen m-2`};
const FormContainer = styled.div`
  width: 100vw;
  flex: 0 0 auto;
  margin: 0;
  background: white;
  @media (min-width: 768px) {
    width: 35rem;
    margin: 0.5rem;
  }
`;

// ${tw`w-108 md:w-64 text-gray-700 bg-white m-2`};
const SidePanelContainer = styled.div`
  width: 100vw;
  flex: 0 0 auto;
  margin: 0;
  background: white;
  @media (min-width: 768px) {
    width: 19rem;
    margin: 0.5rem;
    }
  }
`;

// tw`border-solid border border-gray-200 text-white bg-pink-500 hover:bg-pink-400 cursor-pointer mt-8 mb-4 ml-4 p-3 text-center`
const Button = styled.button`
  border: none;
  color: white;
  width: 10rem;
  font-size: 1rem;
  background: #ed1968;
  cursor: pointer;
  margin: 2rem 0 2rem 2rem;
  padding: 0.75rem 1rem;
  text-align: center;
  &:hover {
    background: #ff5bb3;
  }
`;

const App = () => {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const schemaArray = [
    Page1Schema,
    Page2Schema,
    Page3Schema,
    Page4Schema,
    Page5Schema,
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const loadingSpinner = (
    <>
      <Dialog fullScreen={false} open={open} aria-labelledby="loading-text">
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={loadingGif} alt="loading..." />
          <br />
          <p id="loading-text">Processing now...</p>
        </DialogContent>
      </Dialog>
    </>
  );

  const prevStep = () => {
    // Prev Step dont undes 0
    setCurrentStep(Math.max(currentStep - 1, 0));
  };

  const nextStep = (props) => {
    // NEXT STEP dont go over Max step

    if (props.isValid && currentStep < 4) {
      setCurrentStep(() => currentStep + 1);
      props.validateForm();
      props.setTouched({});
      window.scrollTo(0, 0);
    }
  };

  const step1 = (props) => {
    if (currentStep === 0) {
      return (
        <div>
          <Page1 />
          <Button
            type="button"
            onClick={() => {
              props.isValid && currentStep < 1
                ? nextStep(props)
                : alert(
                  "Please make sure all the fields marked with * are filled"
                );
              window.scroll({
                top: 700,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            NEXT STEP
          </Button>
        </div>
      );
    }
  };

  const step2 = (props) => {
    if (currentStep === 1) {
      return (
        <div>
          <Page2 />

          <Button type="button" onClick={prevStep}>
            PREV
          </Button>

          <Button
            type="button"
            onClick={() => {
              props.isValid && currentStep === 1
                ? nextStep(props)
                : alert(
                  "Please make sure all the fields marked with * are filled"
                );
              window.scroll({
                top: 700,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            NEXT STEP
          </Button>
        </div>
      );
    }
  };

  const step3 = (props) => {
    if (currentStep === 2) {
      return (
        <div>
          <Page3 />

          <Button type="button" onClick={prevStep}>
            PREV
          </Button>

          <Button
            type="button"
            onClick={() => {
              props.isValid && currentStep === 2
                ? nextStep(props)
                : alert(
                  "Please make sure all the fields marked with * are filled"
                );
              window.scroll({
                top: 700,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            NEXT STEP
          </Button>
        </div>
      );
    }
  };

  const step4 = (props) => {
    if (currentStep === 3) {
      return (
        <div>
          <Page4 />

          <Button type="button" onClick={prevStep}>
            PREV
          </Button>

          <Button
            type="button"
            onClick={() => {
              props.isValid && currentStep === 3
                ? nextStep(props)
                : alert(
                  "Please make sure all the fields marked with * are filled"
                );
              window.scroll({
                top: 700,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            NEXT STEP
          </Button>
        </div>
      );
    }
  };

  const step5 = (props) => {
    if (currentStep === 4) {
      return (
        <>
          <div>
            <Page5 />

            <Button type="button" onClick={prevStep}>
              PREV
            </Button>
            {!props.isValid && (
              <Button
                type="button"
                onClick={(props) => {
                  if (!props.isValid) {
                    alert(
                      "Please fill in the fields marked with * before submitting"
                    );
                    window.scroll({
                      top: 700,
                      left: 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                SUBMIT
              </Button>
            )}
            {props.isValid && currentStep === 4 && (
              <Button id="submitButton" type="submit" onClick={handleOpen}>
                SUBMIT
              </Button>
            )}
          </div>
        </>
      );
    }
  };

  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <Formik
      enableReinitialize
      initialValues={{
        unitBedsBaths: "",
        unitLevels: "",
        houseBedsBaths: "",
        storeys: "",
        houseLivingSpaces: "",
        livingSpacesUnit: "",
        residence: "",
        changeBedlinen: false,
        budgetAmount: "",
        participantFName: "",
        participantLName: "",
        participantNDISRef: "",
        participantMobile: "",
        participantPhone: "",
        participantEmail: "",
        participantDOB: "",
        streetNumber: "",
        streetName: "",
        citySuburb: "",
        state: "",
        postCode: "",
        nomineeFName: "",
        nomineeLName: "",
        nomineePhone: "",
        nomineeEmail: "",
        whichDay: "",
        startDate: "",
        endDate: "",
        planManagement: "",
        daysAndTimes: "",
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={schemaArray[currentStep]}
    >
      {(props) => (
        <Body>
          <PageBanner style={{ backgroundSize: "cover" }} />
          <PageWrapper>
            <FormTitle>Just a few minutes away...</FormTitle>
            <ProgressBar currentStep={currentStep} />
            <FormContainer>
              <Form id="regDeepForm">
                {step1(props)}
                {step2(props)}
                {step3(props)}
                {step4(props)}
                {step5(props)}
                {loadingSpinner}
              </Form>
            </FormContainer>
            <SidePanelContainer>
              <SidePanel />
            </SidePanelContainer>
          </PageWrapper>
        </Body>
      )}
    </Formik>
  );
};

export default App;
