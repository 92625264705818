import React, { useEffect, useRef } from "react";
import { Field, connect, getIn } from "formik";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import FadeIn from "react-fade-in";
import { Label, CouponInput } from "../styles/InputStyles";
//import { ErrorMessage } from "../utilities/utilities";

import "../styles/customStyles.css";

const Page5 = (props) => {
  /* connect formik props to component */
  const values = getIn(props.formik.values);
  //const errors = getIn(props.formik.errors);
  const setFieldValue = getIn(props.formik.setFieldValue);
  //const touched = getIn(props.formik.touched);

  // Section References
  // const alarmGateCode = useRef(null);
  const parkingDetails = useRef(null);
  const referredBy = useRef(null);
  const newsletter = useRef(null);

  useEffect(() => {
    /* resets component values if their parent's value is null or empty */
  }, []);

  return (
    <UberSection title="ADDITIONAL DETAILS">
      <div ref={parkingDetails}>
        <FadeIn>
          <Section
            title="Parking Details"
            info="(i.e. park on street, off street parking available, directions etc.)"
          >
            <Field component="textarea" name="parkingDetails" rows="10" />
          </Section>
        </FadeIn>
      </div>
      <div ref={referredBy}>
        <FadeIn>
          <Section title="How did you hear about Sistability?">
            <Field name="referredBy" component="select">
              <option value="">Please Select an Option</option>
              <option value="NDIS Provider Finder">NDIS Provider Finder</option>
              <option value="Referred by Family/Friend">
                Referred by Family/Friend
              </option>
              <option value="Referred by Support Coordinator">
                Referred by Support Coordinator
              </option>
              <option value="Referred by Plan Manager">
                Referred by Plan Manager
              </option>
              <option value="Google Search">Google Search</option>
              <option value="Northern Rivers Times">
                Northern Rivers Times
              </option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="Vehicle Advertisement">
                Vehicle Advertisement
              </option>
              <option value="Flyer/Marketing Collateral">
                Flyer/Marketing Collateral
              </option>
              <option value="Review Site">
                Review Site (i.e. Clickability)
              </option>
              <option value="Other">Other (please specify)</option>
            </Field>
          </Section>
          {values.referredBy === "Other" && (
            <FadeIn>
              <Section title="Please tell us how you heard about us.">
                <Field component="textarea" name="referredByOther" rows="10" />
              </Section>
            </FadeIn>
          )}
        </FadeIn>
      </div>
      {/* Currently not proving Gardening Services. Keep for later */}
      {/* <div>
        <FadeIn>
          <Section title="Are you interested in garden maintenance services?*">
            <Field name="gardenMaintenance" component="select">
              <option value="">Please Select an Option</option>
              <option value="No Thanks">No Thanks</option>
              <option value="Yes Please">Yes Please</option>
              <option value="Not Sure">Not Sure</option>
            </Field>
            {touched.gardenMaintenance && errors.gardenMaintenance && (
              <ErrorMessage>{errors.gardenMaintenance}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      </div> */}
      <div ref={newsletter}>
        <FadeIn>
          <Section title="Would you like to recieve Sistability's monthly eNewsletter?">
            <Field
              name="newsletter"
              id="newsletter"
              type="checkbox"
              label="Yes Please"
            >
              {({ field }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Label htmlFor={field.id}>
                    <span>Yes Please </span>
                    <input
                      onClick={() =>
                        setFieldValue("newsletter", !values.newsletter)
                      }
                      type="checkbox"
                    />
                  </Label>
                </div>
              )}
            </Field>
          </Section>
        </FadeIn>
      </div>
      <div>
        <FadeIn>
          <Section>
            <Field name="coupon" id="coupon">
              {({ field }) => (
                <>
                  <Label htmlFor={field.id}>
                    <span>Coupon code: </span>
                    <CouponInput {...field} type="text" value={field.value} />
                  </Label>
                </>
              )}
            </Field>
          </Section>
        </FadeIn>
      </div>
    </UberSection>
  );
};

export default connect(Page5);
