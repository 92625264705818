import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import onFormSubmit from "./onFormSubmit";

const handleSubmit = async (values) => {
  setTimeout(() => {
    let timeStamp = new Date()
      .toLocaleString("en-GB", { timeZone: "Australia/Sydney" })
      .split(" ");
    timeStamp.length = 5;
    timeStamp = timeStamp.join(" ").trim();

    let data = {
      Timestamp: timeStamp,
      ...values,
    };

    const docID = new Date()
      .toISOString()
      .split("T")
      .join(" ")
      .split("Z")
      .join(" ")
      .trim();

    const newDocRef = firebase
      .firestore()
      .collection("regDeepBookings")
      .doc(docID);

    firebase
      .auth()
      .signInAnonymously()
      .then(() => { })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(
          "Oops. Something went wrong: " +
          errorCode +
          " " +
          errorMessage +
          ". Please refresh the page and try again."
        );
      });

    firebase.auth().onAuthStateChanged((user) => {
      try {
        if (user) {
          var uid = user.uid;
          data = { uid: uid, ...data };
          // eslint-disable-next-line no-undef
          newDocRef
            .set(data)
            .then(() => {
              console.log("form submitted!");
            })
            .catch((error) => {
              alert("oops, there's an error: ", error);
            });
        }
      } catch (error) {
        console.log("Error getting user auth: " + error);
      }
    });

    onFormSubmit(values);
  }, 1000);
};

export default handleSubmit;
