import * as Yup from "yup";

const page2Schema = Yup.object().shape({
  planManagement: Yup.string().required(
    "This section is required. Please select yes or no."
  ),
  planManagerCompany: Yup.string().when("planManagement", {
    is: "PLAN MANAGED",
    then: Yup.string().required(
      "This is required. Please enter the company name."
    ),
  }),
  planManagerEmail: Yup.string().when("planManagement", {
    is: "PLAN MANAGED",
    then: Yup.string().required(
      "This is required. Please enter an email address for invoicing."
    ),
  }),
  planStartDate: Yup.string()
    .required(
      "This section is required. Please use the datepicker to select a date or type it in this format: 'DD/MM/YYYY'."
    )
    .nullable(),
  planEndDate: Yup.string()
    .required(
      "This section is required. Please use the datepicker to select a date or type it in this format: 'DD/MM/YYYY'."
    )
    .nullable(),
  budgetAmount: Yup.number()
    .required("This section is required. Please enter a number.")
    .nullable(),
});

export default page2Schema;
