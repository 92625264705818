import React from "react";
import styled from "styled-components/macro";
import { FiPlus, FiMinus } from "react-icons/fi";

const ComponentWrapper = styled.div`
  margin: ${(props) => props.wrapperMargin || 0};
  width: ${(props) => props.wrapperWidth};
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  margin: 0 1rem;
  width: 7.75rem;
  height: ${7.75 * 0.33 + "rem"};
  padding: 0 0 1rem 0;
  flex: none;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const Button = styled.button`
  background: white;
  color: #ed1968;
  border: 1px solid LightGray;
  padding: 0.8rem 0;
  outline: 0;
  width: ${7.75 * 0.33 + "rem"};
  height: ${7.75 * 0.33 + "rem"};
  float: left;
  margin: auto 0;
  &:not(:last-child) {
    border-right: none;
  }
`;

const CentreButton = styled.button`
  background: white;
  color: #ed1968;
  border: 1px solid LightGray;
  border-right: none;
  padding: 0;
  outline: 0;
  width: ${7.75 * 0.33 + "rem"};
  height: ${7.75 * 0.33 + "rem"};
  float: left;
  margin: auto 0;
`;

const AmountButton = ({
  field,
  form: { setFieldValue },
  amountFloor,
  amountCeiling,
  label,
}) => {
  const value = field.value || 0; // If this causes issues then set actual value in Formik initialValues prop.

  const handleAmountChange = (amount) => {
    const newValue = value + amount;

    if (!isNaN(amountFloor) && newValue < amountFloor) {
      setFieldValue(field.name, amountFloor);
    } else if (!isNaN(amountCeiling) && newValue > amountCeiling) {
      setFieldValue(field.name, amountCeiling);
    } else {
      setFieldValue(field.name, newValue);
    }
  };

  return (
    <ComponentWrapper>
      {label && label !== "" ? (
        <p
          style={{
            width: "8.5rem",
            textAlign: "center",
            marginLeft: "0.5rem",
            lineHeight: "1rem",
          }}
        >
          {label}
        </p>
      ) : null}
      <ButtonWrapper>
        <Button
          type="button"
          style={{
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
          onClick={() => handleAmountChange(-1)}
        >
          <FiMinus />
        </Button>
        <CentreButton>{value}</CentreButton>
        <Button
          type="button"
          style={{
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
          onClick={() => handleAmountChange(+1)}
        >
          <FiPlus />
        </Button>
      </ButtonWrapper>
    </ComponentWrapper>
  );
};

export default AmountButton;
