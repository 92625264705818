import React, { useRef } from "react";
import styled from "styled-components/macro";
import ContactDetails from "../formSections/ContactDetails";

/* styled-components styles */
const HeaderSection = styled.div`
  border-width: 2px;
  border-color: #f3f3f3;
  border-style: none none solid none;
  width: 100%;
  padding: 0.5rem 0;
  color: #666;
  text-align: center;
  @media (max-width: 760px) {
    padding: 0;
  }
`;

const Page1 = (props) => {
  // Section References
  const participantFName = useRef(null);
  const participantLName = useRef(null);
  const participantNDISRef = useRef(null);
  const participantAddress = useRef(null);
  const participantPhone = useRef(null);
  const participantEmail = useRef(null);
  const participantDOB = useRef(null);
  const preferredContactMethod = useRef(null);
  const hasNominee = useRef(null);
  const NomineeDetails = useRef(null);

  return (
    <>
      <HeaderSection>
        <br />
        <h1
          style={{
            width: "100%",
            margin: "0 auto .5rem auto",
            color: "#666",
            fontSize: "2rem",
            fontWeight: "200",
          }}
        >
          Booking Request Form
        </h1>
        <p
          style={{
            width: "100%",
            margin: "0 auto 0 auto",
            color: "#666",
            fontSize: ".83rem",
          }}
        >
          * These fields are required.
        </p>
        <br />
      </HeaderSection>
      <ContactDetails
        refs={[
          participantFName,
          participantLName,
          participantNDISRef,
          participantAddress,
          participantPhone,
          participantEmail,
          participantDOB,
          preferredContactMethod,
          hasNominee,
          NomineeDetails,
        ]}
      />
    </>
  );
};

export default Page1;
