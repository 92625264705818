import React, { useRef } from "react";
import { connect, getIn } from "formik";
import Section from "../containers/Section";
import FadeIn from "react-fade-in";
import Datepicker from "../components/Datepicker/Datepicker";
// import Options from "../components/Buttons/SelectOptions";
import DaysAndTimes from "../components/Buttons/DaysAndTimes";
import { ErrorMessage } from "../utilities/utilities";
import "../styles/customStyles.css";

const TimeAndDate = (props) => {
  const errors = getIn(props.formik.errors);
  const touched = getIn(props.formik.touched);
  // const values = getIn(props.formik.values);
  // const name = getIn(props.formik.name);

  // Section References
  // const startDate = useRef(null);
  const endDate = useRef(null);
  //const servicePriorities = useRef(null);

  return (
    <>
      <DaysAndTimes />
      {/* <div>
        <FadeIn>
          <Section title="How soon would you like these services to start?*">
            <Field name="startDate" component="select">
              <option value="">Select an option</option>
              <option value="ASAP">ASAP</option>
              <option value="Next Week">Next Week</option>
              <option value="Within the Month">Within the Month</option>
            </Field>
            {touched.startDate && errors.startDate && (
              <ErrorMessage>{errors.startDate}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      </div> */}
      <div ref={endDate}>
        <FadeIn>
          <Section
            title="When would you like this service to end?*"
            info="If you would like ongoing services, please select your
                    current plan end date in this field."
          >
            <Datepicker name="endDate" />
            {touched.endDate && errors.endDate && (
              <ErrorMessage>{errors.endDate}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      </div>
      {/* <div ref={servicePriorities}>
        <FadeIn>
          <Section title="Please list your service priorities in the comment box below to make the best use of your booked time.">
            <Field component="textarea" name="servicePriorities" rows="10" />
          </Section>
        </FadeIn>
      </div> */}
    </>
  );
};

export default connect(TimeAndDate);
