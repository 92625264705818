import React, { useState, useEffect } from "react";
import { connect, getIn } from "formik";
import Section from "../../containers/Section";
import styled from "styled-components";

const DayAndTime = styled.div`
  display: flex;
  border: 1px solid lightgray;
  flex-direction: column;
  width: 7.5rem;
  align-items: center;
  border-radius: 15px;
  margin: 1rem;
  padding: 0 0 0.5rem;
`;

const DayButtonWrapper = styled.div`
    flex-direction: row;
    flex-wrap; wrap;
`;

const Button = styled.button`
  border-style: none;
  background: none;
  outline: none;
  padding: 5px;
  color: ${(props) => (props.active === true ? "white" : "#666")};
`;

const Div = styled.div`
  width: 5rem;
  text-align: center;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${(props) => (props.active === true ? "#ed1968" : "white")};
`;

const DivBottom = styled.div`
  width: 5rem;
  text-align: center;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${(props) => (props.active === true ? "#ed1968" : "white")};
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
`;

const P = styled.p`
  flex: 3;
  text-align: center;
`;

const DayButton = (props) => (
  <Div active={props.active}>
    <Button active={props.active} type="button" onClick={props.clicked}>
      {props.value}
    </Button>
  </Div>
);

const DayButtonBottom = (props) => (
  <DivBottom active={props.active}>
    <Button active={props.active} type="button" onClick={props.clicked}>
      {props.value}
    </Button>
  </DivBottom>
);

const DaysAndTimes = (props) => {
  const setFieldValue = getIn(props.formik.setFieldValue);

  const [daysTimes, setDaysTimes] = useState("");
  const [monMorn, setMonMorn] = useState(false);
  const [monMid, setMonMid] = useState(false);
  const [monArv, setMonArv] = useState(false);
  const [tuesMorn, setTuesMorn] = useState(false);
  const [tuesMid, setTuesMid] = useState(false);
  const [tuesArv, setTuesArv] = useState(false);
  const [wedMorn, setWedMorn] = useState(false);
  const [wedMid, setWedMid] = useState(false);
  const [wedArv, setWedArv] = useState(false);
  const [thursMorn, setThursMorn] = useState(false);
  const [thursMid, setThursMid] = useState(false);
  const [thursArv, setThursArv] = useState(false);
  const [friMorn, setFriMorn] = useState(false);
  const [friMid, setFriMid] = useState(false);
  const [friArv, setFriArv] = useState(false);
  const [satMorn, setSatMorn] = useState(false);
  const [satMid, setSatMid] = useState(false);
  const [satArv, setSatArv] = useState(false);

  const stateHandler = (value, state, setState) => {
    setState(!state);
    const strippedValue = value.replace(" | ", "");
    if (daysTimes.length === 0) {
      setDaysTimes(value.replace(" | ", ""));
      return;
    }
    if (daysTimes.includes(value)) {
      setDaysTimes(daysTimes.replace(value, ""));
    } else if (daysTimes.includes(strippedValue)) {
      setDaysTimes(daysTimes.replace(strippedValue, ""));
    } else {
      setDaysTimes(daysTimes + value);
    }
  };

  useEffect(() => {
    setFieldValue("daysAndTimes", daysTimes);
    if (daysTimes.length === 0) {
      setDaysTimes("");
    }
  }, [daysTimes, setFieldValue]);

  return (
    <Section
      title="Please select the days and times that suit you for us to come"
      info="This is for you to let us know your availability. You can select more than one option per day."
    >
      <DayAndTime>
        <P>MONDAY</P>
        <DayButtonWrapper>
          <DayButton
            name="mondayMorning"
            id="mondayMorning"
            value="Morning"
            clicked={() =>
              stateHandler(" | Monday Morning", monMorn, setMonMorn)
            }
            active={monMorn}
          />
          <DayButton
            name="mondayMidday"
            id="mondayMidday"
            value="Midday"
            clicked={() => stateHandler(" | Monday Midday", monMid, setMonMid)}
            active={monMid}
          />
          <DayButtonBottom
            name="mondayAfternoon"
            id="mondayAfternoon"
            value="Afternoon"
            clicked={() =>
              stateHandler(" | Monday Afternoon", monArv, setMonArv)
            }
            active={monArv}
          />
        </DayButtonWrapper>
      </DayAndTime>
      <DayAndTime>
        <P>TUESDAY</P>
        <DayButtonWrapper>
          <DayButton
            name="tuesdayMorning"
            id="tuesdayMorning"
            value="Morning"
            clicked={() => {
              stateHandler(" | Tuesday Morning", tuesMorn, setTuesMorn);
            }}
            active={tuesMorn}
          />
          <DayButton
            name="tuesdayMidday"
            id="tuesdayMidday"
            value="Midday"
            clicked={() => {
              stateHandler(" | Tuesday Midday", tuesMid, setTuesMid);
            }}
            active={tuesMid}
          />
          <DayButtonBottom
            name="tuesdayAfternoon"
            id="tuesdayAfternoon"
            value="Afternoon"
            clicked={() => {
              stateHandler(" | Tuesday Afternoon", tuesArv, setTuesArv);
            }}
            active={tuesArv}
          />
        </DayButtonWrapper>
      </DayAndTime>
      <DayAndTime>
        <P>WEDNESDAY</P>
        <DayButtonWrapper>
          <DayButton
            name="wednesdayMorning"
            id="wednesdayMorning"
            value="Morning"
            clicked={() => {
              stateHandler(" | Wednesday Morning", wedMorn, setWedMorn);
            }}
            active={wedMorn}
          />
          <DayButton
            name="wednesdayMidday"
            id="wednesdayMidday"
            value="Midday"
            clicked={() => {
              stateHandler(" | Wednesday Midday", wedMid, setWedMid);
            }}
            active={wedMid}
          />
          <DayButtonBottom
            name="wednesdayAfternoon"
            id="wednesdayAfternoon"
            value="Afternoon"
            clicked={() => {
              stateHandler(" | Wednesday Afternoon", wedArv, setWedArv);
            }}
            active={wedArv}
          />
        </DayButtonWrapper>
      </DayAndTime>
      <DayAndTime>
        <P>THURSDAY</P>
        <DayButtonWrapper>
          <DayButton
            name="thursdayMorning"
            id="thursdayMorning"
            value="Morning"
            clicked={() => {
              stateHandler(" | Thursday Morning", thursMorn, setThursMorn);
            }}
            active={thursMorn}
          />
          <DayButton
            name="thursdayMidday"
            id="thursdayMidday"
            value="Midday"
            clicked={() => {
              stateHandler(" | Thursday Midday", thursMid, setThursMid);
            }}
            active={thursMid}
          />
          <DayButtonBottom
            name="thursdayAfternoon"
            id="thursdayAfternoon"
            value="Afternoon"
            clicked={() => {
              stateHandler(" | Thursday Afternoon", thursArv, setThursArv);
            }}
            active={thursArv}
          />
        </DayButtonWrapper>
      </DayAndTime>
      <DayAndTime>
        <P>FRIDAY</P>
        <DayButtonWrapper>
          <DayButton
            name="fridayMorning"
            id="fridayMorning"
            value="Morning"
            clicked={() => {
              stateHandler(" | Friday Morning", friMorn, setFriMorn);
            }}
            active={friMorn}
          />
          <DayButton
            name="fridayMidday"
            id="fridayMidday"
            value="Midday"
            clicked={() => {
              stateHandler(" | Friday Midday", friMid, setFriMid);
            }}
            active={friMid}
          />
          <DayButtonBottom
            name="fridayAfternoon"
            id="fridayAfternoon"
            value="Afternoon"
            clicked={() => {
              stateHandler(" | Friday Afternoon", friArv, setFriArv);
            }}
            active={friArv}
          />
        </DayButtonWrapper>
      </DayAndTime>
      <DayAndTime>
        <P>SATURDAY</P>
        <DayButtonWrapper>
          <DayButton
            name="saturdayMorning"
            id="saturdayMorning"
            value="Morning"
            clicked={() => {
              stateHandler(" | Saturday Morning", satMorn, setSatMorn);
            }}
            active={satMorn}
          />
          <DayButton
            name="saturdayMidday"
            id="saturdayMidday"
            value="Midday"
            clicked={() => {
              stateHandler(" | Saturday Midday", satMid, setSatMid);
            }}
            active={satMid}
          />
          <DayButtonBottom
            name="saturdayAfternoon"
            id="saturdayAfternoon"
            value="Afternoon"
            clicked={() => {
              stateHandler(" | Saturday Afternoon", satArv, setSatArv);
            }}
            active={satArv}
          />
        </DayButtonWrapper>
      </DayAndTime>
    </Section>
  );
};

export default connect(DaysAndTimes);
