import styled, { css } from "styled-components/macro";

export const EmDiv = styled.div`
  text-align: justify;
  background: LemonChiffon;
  padding: 1rem 1.5rem;
`;

export const Label = styled.label`
  margin: 0.5rem 1.5rem;
  width: 90%;
  text-align: left;
`;

export const Input = styled.input`
  margin: 0.5rem 1.5rem;
  width: 90%;
  height: 2rem;
  font: 400 1rem Open Sans;
  &:focus {
    border-color: #ed1964;
    outline: none;
    border-style: solid;
    border-width: 2px;
  }
  ${(props) => {
    props.touched &&
      props.errors &&
      css`
    border-color: red;
    border-style: solid;
    border-width: 1px
    outline: none;
    `;
  }}
`;

export const CouponInput = styled.input`
  margin: 0.5rem 1.5rem;
  width: 50%;
  height: 2rem;
  font: 400 1rem Open Sans;
  &:focus {
    border-color: #ed1964;
    outline: none;
    border-style: solid;
    border-width: 2px;
  }
  ${(props) => {
    props.touched &&
      props.errors &&
      css`
    border-color: red;
    border-style: solid;
    border-width: 1px
    outline: none;
    `;
  }}
`;
