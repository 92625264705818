// Plan Management Details

import React, { useEffect } from "react";
import { Field, connect, getIn } from "formik";

import FadeIn from "react-fade-in";
import { scrollToSection } from "../utilities/utilities";
import SelectableOption from "../components/SelectableOptions";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import PlanManager from "./PlanManager";
import { ErrorMessage } from "../utilities/utilities";

const PlanManagementDetails = (props) => {
  const touched = getIn(props.formik.touched);
  const errors = getIn(props.formik.errors);
  const values = getIn(props.formik.values);
  const setFieldValue = getIn(props.formik.setFieldValue);

  useEffect(() => {
    if (values.planManagement !== "PLAN MANAGED") {
      if (values.planManagerCompany !== "") {
        setFieldValue("planManagerCompany", "");
      }
      if (values.planManagerFName !== "") {
        setFieldValue("planManagerFName", "");
      }
      if (values.planManagerLName !== "") {
        setFieldValue("planManagerLName", "");
      }
      if (values.planManagerPhone !== "") {
        setFieldValue("planManagerPhone", "");
      }
      if (values.planManagerEmail !== "") {
        setFieldValue("planManagerEmail", "");
      }
    }
  }, [
    setFieldValue,
    values.planManagement,
    values.planManagerCompany,
    values.planManagerFName,
    values.planManagerLName,
    values.planManagerPhone,
    values.planManagerEmail,
  ]);

  return (
    <FadeIn>
      <UberSection title="PLAN MANAGEMENT DETAILS">
        <Section title="How is the Participant's plan managed?*">
          <Field
            name="planManagement"
            component={SelectableOption}
            value="NDIA MANAGED"
            type="radio"
            label="NDIA MANAGED"
            onClick={() => scrollToSection(props.refs[1])}
          />
          <Field
            name="planManagement"
            component={SelectableOption}
            value="SELF MANAGED"
            type="radio"
            label="SELF MANAGED"
            onClick={() => scrollToSection(props.refs[1])}
          />
          <Field
            name="planManagement"
            component={SelectableOption}
            value="PLAN MANAGED"
            type="radio"
            label="PLAN MANAGED"
            onClick={() => scrollToSection(props.refs[0])}
          />
          {touched.planManagement && errors.planManagement && (
            <ErrorMessage>{errors.planManagement}</ErrorMessage>
          )}
        </Section>
        <div ref={props.refs[0]}>
          {values.planManagement === "PLAN MANAGED" ? <PlanManager /> : null}
        </div>
      </UberSection>
    </FadeIn>
  );
};

export default connect(PlanManagementDetails);
