import React from "react";
import styled, { css } from "styled-components/macro";
import { Input, SelectableWrapper } from "../styles/ButtonStyles";

const Label = styled.label`
  display: block;
  border: 1px solid LightGray;
  width: 8.5rem;
  text-align: center;
  padding: 0.75rem;
  margin: 0 1rem;
  flex: 0 0 auto;
  &:hover {
    background: #f9f9f9;
  }
  ${(props) =>
    props.checked &&
    css`
      background: #ed1968;
      color: white;
      border: 1px solid #ed1968;
      &:hover {
        background: #ed1968;
      }
    `}
`;

const SelectableOption = ({
  field,
  form: { values, handleChange, handleBlur },
  id,
  onClick,
  type,
}) => {
  return (
    <SelectableWrapper>
      <Label htmlFor={id} checked={values[field.name] === field.value}>
        {field.value}
        <Input
          id={id}
          name={field.name}
          checked={values[field.name] === field.value}
          value={field.value}
          onChange={handleChange}
          onBlur={handleBlur}
          type={type}
          onClick={onClick}
        />
      </Label>
    </SelectableWrapper>
  );
};

export default SelectableOption;
