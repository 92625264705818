import React, { useRef } from "react";
import PlanManagementDetails from "../formSections/PMDetails";
import ApprovedPlanBudget from "../formSections/ApprovedPlanBudget";
// import { getIn, connect } from "formik";

const Page2 = (props) => {
  // const errors = getIn(props.formik.errors);

  // Section References
  const planManager = useRef(null);
  const planStartDate = useRef(null);
  const planEndDate = useRef(null);
  const budgetAmount = useRef(null);
  const dailyLifeApproved = useRef(null);
  const budgetItems = useRef(null);

  return (
    <>
      <PlanManagementDetails refs={[planManager, planStartDate]} />
      <ApprovedPlanBudget
        refs={[
          planStartDate,
          planEndDate,
          budgetAmount,
          dailyLifeApproved,
          budgetItems,
        ]}
      />
    </>
  );
};

export default Page2;
