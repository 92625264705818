import React from "react";
import styled from "styled-components";
import { Field, getIn, connect } from "formik";
import Section from "../containers/Section";
import AmountButton from "../components/Buttons/AmountButton";
import { ErrorMessage } from "../utilities/utilities";

const Div = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0.5rem;
`;

const FormGroup = styled.div`
  paddingtop: 10px;
`;

const P = styled.p`
  margin-left: 1.5rem;
`;

const HouseInfo = (props) => {
  const errors = getIn(props.formik.errors);
  const touched = getIn(props.formik.touched);

  return (
    <React.Fragment>
      <div>
        <Section>
          <FormGroup>
            <P>Number of Beds/ Baths*</P>
            <Field name="houseBedsBaths" component="select">
              <option value=""> Select an option </option>
              <option value="1 Bed/ 1 Bath"> 1 Bed/ 1 Bath </option>
              <option value="2 Bed/ 1 Bath"> 2 Bed/ 1 Bath </option>
              <option value="2 Bed/ 1.5 Bath"> 2 Bed/ 1.5 Bath </option>
              <option value="3 Bed/ 1 Bath"> 3 Bed/ 1 Bath </option>
              <option value="3 Bed/ 1.5 Bath"> 3 Bed/ 1.5 Bath </option>
              <option value="3 Bed/ 2 Bath"> 3 Bed/ 2 Bath </option>
              <option value="3 Bed/ 2.5 Bath"> 3 Bed/ 2.5 Bath </option>
              <option value="4 Bed/ 1 Bath"> 4 Bed/ 1 Bath </option>
              <option value="4 Bed/ 1.5 Bath"> 4 Bed/ 1.5 Bath </option>
              <option value="4 Bed/ 2 Bath"> 4 Bed/ 2 Bath </option>
              <option value="4 Bed/ 2.5 Bath"> 4 Bed/ 2.5 Bath </option>
              <option value="4 Bed/ 3 Bath"> 4 Bed/ 3 Bath </option>
              <option value="4 Bed/ 3.5 Bath"> 4 Bed/ 3.5 Bath </option>
              <option value="4 Bed/ 4 Bath"> 4 Bed/ 4 Bath </option>
              <option value="5 Bed/ 1 Bath"> 5 Bed/ 1 Bath </option>
              <option value="5 Bed/ 1.5 Bath"> 5 Bed/ 1.5 Bath </option>
              <option value="5 Bed/ 2 Bath"> 5 Bed/ 2 Bath </option>
              <option value="5 Bed/ 2.5 Bath"> 5 Bed/ 2.5 Bath </option>
              <option value="5 Bed/ 3 Bath"> 5 Bed/ 3 Bath </option>
              <option value="5 Bed/ 3.5 Bath"> 5 Bed/ 3.5 Bath </option>
              <option value="5 Bed/ 4 Bath"> 5 Bed/ 4 Bath </option>
              <option value="5 Bed/ 4.5 Bath"> 5 Bed/ 4.5 Bath </option>
              <option value="5 Bed/ 5 Bath"> 5 Bed/ 5 Bath </option>
            </Field>
            {touched.houseBedsBaths && errors.houseBedsBaths && (
              <ErrorMessage>{errors.houseBedsBaths}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <P>Storeys*</P>
            <Field name="storeys" component="select">
              <option value="">Select an option</option>
              <option value="Single">Single</option>
              <option value="Double">Double</option>
            </Field>
            {touched.storeys && errors.storeys && (
              <ErrorMessage>{errors.storeys}</ErrorMessage>
            )}
          </FormGroup>
        </Section>
      </div>
      <div>
        <Section
          title="Does the home have any additional living spaces?"
          style={{
            paddingBottom: 0,
          }}
          info="Our deep cleaning package includes the cleaning of one (1) living and one
        (1) dining space. If you have any additional living spaces that require
        cleaning, please indicate the number of rooms below including external
        office spaces, converted garage, playrooms, gyms etc."
        >
          <Div>
            <Field
              name="houseLivingSpaces"
              component={AmountButton}
              type="button"
              id="houseLivingSpaces"
              label=""
              amountFloor={0}
              amountCeiling={5}
              wrapperWidth="20rem"
              buttonSize="8.5rem"
              style={{ alignSelf: "center" }}
            />
          </Div>
        </Section>
      </div>
    </React.Fragment>
  );
};

export default connect(HouseInfo);
