import React from "react";
import { Field, connect, getIn } from "formik";

import FadeIn from "react-fade-in";
import Section from "../containers/Section";
import { ErrorMessage } from "../utilities/utilities";
import { Label, Input } from "../styles/InputStyles";

const ParticipantAddress = (props) => {
  //   const values = getIn(props.formik.values);
  const errors = getIn(props.formik.errors);
  const touched = getIn(props.formik.touched);
  return (
    <>
      {!errors.dailyLifeApproved && (
        <FadeIn>
          <Section title="Participant Address:">
            <Field name="streetNumber" id="streetNumber" type="number">
              {({ field }) => (
                <>
                  <Label htmlFor={field.id}>Unit/Street Number*:</Label>
                  <Input {...field} />
                </>
              )}
            </Field>
            {touched.streetNumber && errors.streetNumber && (
              <ErrorMessage>{errors.streetNumber}</ErrorMessage>
            )}
            <Field name="streetName" id="streetName" type="text">
              {({ field }) => (
                <>
                  <Label htmlFor={field.id}>Street Name*:</Label>
                  <Input {...field} />
                </>
              )}
            </Field>
            {touched.streetName && errors.streetName && (
              <ErrorMessage>{errors.streetName}</ErrorMessage>
            )}
            <Field name="citySuburb" id="citySuburb" type="text">
              {({ field }) => (
                <>
                  <Label htmlFor={field.id}>City*:</Label>
                  <Input {...field} />
                </>
              )}
            </Field>
            {touched.citySuburb && errors.citySuburb && (
              <ErrorMessage>{errors.citySuburb}</ErrorMessage>
            )}
            <Field name="state" id="state" type="text">
              {({ field }) => (
                <>
                  <Label htmlFor={field.id}>State*:</Label>
                  <Input {...field} />
                </>
              )}
            </Field>
            {touched.state && errors.state && (
              <ErrorMessage>{errors.state}</ErrorMessage>
            )}
            <Field name="postCode" id="postCode" type="text">
              {({ field }) => (
                <>
                  <Label htmlFor={field.id}>Postcode*:</Label>
                  <Input {...field} />
                </>
              )}
            </Field>
            {touched.postCode && errors.postCode && (
              <ErrorMessage>{errors.postCode}</ErrorMessage>
            )}
          </Section>
        </FadeIn>
      )}
    </>
  );
};

export default connect(ParticipantAddress);
