import styled from "styled-components/macro";

export const Input = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const SelectableWrapper = styled.div`
  margin: 0.5rem auto 0.5rem 0.75rem;
  padding: 0.5rem 1rem;
  overflow: hidden;
`;

export const ExtrasWrapper = styled.div`
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const OptExtrasWrapper = styled.div`
  margin: 0.5rem auto 0.5rem auto;
  padding: 0.5rem 1rem;
  overflow: hidden;
  flex: 1;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

// export const Select = styled.select`
//       height: calc(2.4em + 1rem + 2px);
//       display: block;
//       font: 400 1.2rem Open Sans;
//       border: 1px solid LightGray;
//       width: 10rem;
//       text-align: center;
//       padding: .75rem;
//       margin: 0 1rem;
//       flex: 0 0 auto;
//       &:hover {
//           background: MistyRose;
//       };
//       &:focus {
//         border: 1.5px solid #ed1968;
//         outline: none;
//       }
//     `;
