import React from "react";

import styled from "styled-components/macro";

// ${tw`border-solid border-r-0 border-l-0 border-t-0 border-b border-gray-200 p-2 w-full`}
const SectionWrapper = styled.section`
  border-color: #f3f3f3;
  border-width: 2px;
  border-style: none none solid none;
  width: 90%;
  color: #666;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 20px 20px;
  @media (max-width: 760px) {
    padding: 0;
  }
`;

const P = styled.p`
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  padding: 0 0.5rem;
`;

const Info = styled.span`
  font-size: 0.83rem;
  text-align: justify;
  padding: 0;
`;

// {tw`flex flex-wrap -mx-2 overflow-hidden w-full sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2`}
const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const Section = (props) => (
  <SectionWrapper>
    {props.title ? (
      <P>
        {props.title}{" "}
        {props.info ? (
          <>
            <br />
            <Info>{props.info}</Info>
          </>
        ) : null}
      </P>
    ) : null}
    <Div>{props.children}</Div>
    <br />
  </SectionWrapper>
);

export default Section;
