import * as Yup from "yup";

const page3Schema = Yup.object().shape({
  residence: Yup.string().required(" "),
  unitBedsBaths: Yup.string().when("residence", {
    is: "UNIT",
    then: Yup.string().required("This is required. Please choose an option."),
  }),
  unitLevels: Yup.string().when("residence", {
    is: "UNIT",
    then: Yup.string().required("This is required. Please choose an option."),
  }),
  houseBedsBaths: Yup.string().when("residence", {
    is: "HOUSE",
    then: Yup.string().required("This is required. Please choose an option."),
  }),
  storeys: Yup.string().when("residence", {
    is: "HOUSE",
    then: Yup.string().required("This is required. Please choose an option."),
  }),
  pets: Yup.string().required("This is required. Please choose Yes or No"),
  smokers: Yup.string().required("This is required. Please choose Yes or No"),
  lastProfClean: Yup.string().required(
    "This is required. Please choose an option."
  ),
});

export default page3Schema;
