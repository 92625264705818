import React, { useEffect } from "react";
import { Field, connect, getIn } from "formik";
import Section from "../containers/Section";
import AmountButton from "../components/Buttons/AmountButton";
import SelectableOption from "../components/SelectableOptions";
import ExtrasHeader from "../components/cards/ExtrasHeader";
import FadeIn from "react-fade-in";
import { ExtrasWrapper } from "../styles/ButtonStyles";
// import { Label } from "../styles/InputStyles";
// import styled from "styled-components";
import "../styles/customStyles.css";

/* const Div = styled.div`
  padding: 0 0 20px;
  width: 100%;
  @media (max-width: 760px) {
    padding: 0;
  }
`; */

const Extras = (props) => {
  const values = getIn(props.formik.values);
  const setFieldValue = getIn(props.formik.setFieldValue);

  useEffect(() => {
    /* resets component values if their parent's value is null or empty */

    if (values.internalOvenClean === false && values.ovenCleanFreq !== "") {
      setFieldValue("ovenCleanFreq", "");
    }

    if (values.internalFridgeClean === false && values.fridgeCleanFreq !== "") {
      setFieldValue("fridgeCleanFreq", "");
    }

    if (values.rangehdClean === false && values.rangehdCleanFreq !== "") {
      setFieldValue("rangehdCleanFreq", "");
    }

    if (values.skirtsAndFrames === false && values.skirtsAndFramesFreq !== "") {
      setFieldValue("skirtsAndFramesFreq", "");
    }

    if (values.kitchenCabs === false && values.kitchenCabsFreq !== "") {
      setFieldValue("kitchenCabsFreq", "");
    }

    if (values.pantryClean === false && values.pantryCleansFreq !== "") {
      setFieldValue("pantryCleansFreq", "");
    }

    if (
      values.blindsAndShutters === false &&
      values.blindsAndShuttersFreq !== ""
    ) {
      setFieldValue("rangehdCleanFreq", "");
    }

    if (values.windowClean === false && values.windowCleanFreq !== "") {
      setFieldValue("windowCleanFreq", "");
    }
  }, [
    setFieldValue,
    values.blindsAndShutters,
    values.blindsAndShuttersFreq,
    values.kitchenCabs,
    values.kitchenCabsFreq,
    values.fridgeCleanFreq,
    values.windowClean,
    values.windowCleanFreq,
    values.internalFridgeClean,
    values.internalOvenClean,
    values.ovenCleanFreq,
    values.pantryClean,
    values.pantryCleansFreq,
    values.rangehdClean,
    values.rangehdCleanFreq,
    values.skirtsAndFrames,
    values.skirtsAndFramesFreq,
  ]);

  return (
    <>
      <FadeIn>
        <Section title="Most of our clients who book regular cleaning also add:">
          <ExtrasWrapper>
            <Field
              name="internalOvenClean"
              component={ExtrasHeader}
              id="oven-clean"
              label="Internal Oven Clean"
              icon="icon-oven"
            />
            {values.internalOvenClean && (
              <Field
                name="ovenCleanFreq"
                component={AmountButton}
                id="oven-clean-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="internalFridgeClean"
              component={ExtrasHeader}
              id="fridge-clean"
              label="Internal Fridge Clean"
              icon="icon-fridge"
            />
            {values.internalFridgeClean && (
              <Field
                name="fridgeCleanFreq"
                component={AmountButton}
                id="fridge-cleans-per-yr"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="rangehdClean"
              component={ExtrasHeader}
              id="rangehd-clean"
              label="Rangehood Clean"
              icon="icon-range"
            />
            {values.rangehdClean && (
              <Field
                name="rangehdCleanFreq"
                component={AmountButton}
                id="rangehd-cleans-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="skirtsAndFrames"
              component={ExtrasHeader}
              id="skirts-frames"
              label="Skirting & Frames"
              icon="icon-skirts"
            />
            {values.skirtsAndFrames && (
              <Field
                name="skirtsAndFramesFreq"
                component={AmountButton}
                id="skirts-frames-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="kitchenCabs"
              component={ExtrasHeader}
              id="cabinets-drawers"
              label="Kitchen Cabinets"
              icon="icon-drawers"
            />
            {values.kitchenCabs && (
              <Field
                name="kitchenCabsFreq"
                component={AmountButton}
                id="kitchen-cabs-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="pantryClean"
              component={ExtrasHeader}
              id="pantry-clean"
              label="Internal Pantry Clean"
              icon="icon-pantry"
            />
            {values.pantryClean && (
              <Field
                name="pantryCleansFreq"
                component={AmountButton}
                id="oven-cleans-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="blindsAndShutters"
              component={ExtrasHeader}
              id="blinds-shutters"
              label="Dust Blinds/Shutters"
              icon="icon-blinds"
            />
            {values.blindsAndShutters && (
              <Field
                name="blindsAndShuttersFreq"
                component={AmountButton}
                id="blinds-shutters-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
          <ExtrasWrapper>
            <Field
              name="windowClean"
              component={ExtrasHeader}
              id="window-clean"
              label="Windows"
              icon="icon-window"
            />
            {values.windowClean && (
              <Field
                name="windowCleanFreq"
                component={AmountButton}
                id="window-clean-freq"
                label="Times per plan"
                amountFloor={0}
                amountCeiling={24}
                wrapperWidth="8.5rem"
                buttonSize="7.75rem"
                buttonPadding="0.8rem 0"
              />
            )}
          </ExtrasWrapper>
        </Section>
      </FadeIn>
      {values.windowClean && (
        <FadeIn>
          <Section
            title="You selected 'Windows' from the above options. We just need a bit more info."
            info="Please select whether you need only the inside of the windows
          cleaned or both inside and out. Window cleaning includes
          cleaning of glass panels and frames, wiping down sills and
          ledges and removing any cobwebs – up to 2 step ladder only."
          >
            <Field
              name="windows"
              component={SelectableOption}
              value="Inside Only"
              type="radio"
              label="Inside Only"
              id="widowsInt"
            />
            <Field
              name="windows"
              component={SelectableOption}
              value="Inside & Out"
              type="radio"
              label="Inside & Out"
              id="windowsIntExt"
            />
          </Section>
        </FadeIn>
      )}
    </>
  );
};

export default connect(Extras);
