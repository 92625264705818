import React, { useEffect, useRef } from "react";
import { Field, connect, getIn } from "formik";
import UberSection from "../containers/UberSection";
import Section from "../containers/Section";
import FadeIn from "react-fade-in";

///////////// Utilities /////////////////
import { scrollToSection, ErrorMessage } from "../utilities/utilities";

///////////// Form Sections /////////////////
import UnitInfo from "../formSections/UnitInfo";
import HouseInfo from "../formSections/HouseInfo";
import HouseCondition from "../components/Buttons/HouseCondition";

///////////// Components /////////////////
import SelectableOption from "../components/SelectableOptions";
import AmountButton from "../components/Buttons/AmountButton";

import "../styles/customStyles.css";
import styled from "styled-components";

const Div = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Page3 = (props) => {
  /* connect formik props to component */
  const values = getIn(props.formik.values);
  const errors = getIn(props.formik.errors);
  const setFieldValue = getIn(props.formik.setFieldValue);
  const touched = getIn(props.formik.touched);

  // Section References
  const residence = useRef(null);
  const unitInfo = useRef(null);
  const houseInfo = useRef(null);
  const pets = useRef(null);
  const petsInfo = useRef(null);
  const smokers = useRef(null);
  // const unitBedsBaths = useRef(null);
  // const houseBedsBaths = useRef(null);
  // const unitLevel = useRef(null);
  // const storeys = useRef(null);
  // const houseLivingSpaces = useRef(null);
  // const unitLivingSpaces = useRef(null);
  const lastProfClean = useRef(null);

  useEffect(() => {
    /* resets component values if their parent's value is null or empty */
    if (values.residence === "STUDIO" && values.unitBedsBaths !== "") {
      setFieldValue("unitBedsBaths", "");
    }
    if (values.residence === "STUDIO" && values.unitLevels !== "") {
      setFieldValue("unitLevels", "");
    }
    if (values.residence === "STUDIO" && values.livingSpacesUnit !== "") {
      setFieldValue("unitLivingSpaces", "");
    }
    if (values.residence === "STUDIO" && values.houseBedsBaths !== "") {
      setFieldValue("houseBedsBaths", "");
    }
    if (values.residence === "STUDIO" && values.storeys !== "") {
      setFieldValue("storeys", "");
    }
    if (values.residence === "STUDIO" && values.houseLivingSpaces !== "") {
      setFieldValue("houseLivingSpaces", "");
    }
    if (values.residence === "UNIT" && values.houseBedsBaths !== "") {
      setFieldValue("houseBedsBaths", "");
    }
    if (values.residence === "UNIT" && values.storeys !== "") {
      setFieldValue("storeys", "");
    }
    if (values.residence === "UNIT" && values.houseLivingSpaces !== "") {
      setFieldValue("houseLivingSpaces", "");
    }
    if (values.residence === "HOUSE" && values.unitBedsBaths !== "") {
      setFieldValue("unitBedsBaths", "");
    }
    if (values.residence === "HOUSE" && values.unitLevels !== "") {
      setFieldValue("unitLevels", "");
    }
    if (values.residence === "HOUSE" && values.livingSpacesUnit !== "") {
      setFieldValue("livingSpacesUnit", "");
    }
    if (values.pets !== "YES" && values.dogs !== "") {
      setFieldValue("dogs", "");
    }
    if (values.pets !== "YES" && values.cats !== "") {
      setFieldValue("cats", "");
    }
  }, [
    setFieldValue,
    values.cats,
    values.dogs,
    values.pets,
    values.residence,
    values.unitLevels,
    values.livingSpacesUnit,
    values.unitBedsBaths,
    values.storeys,
    values.houseBedsBaths,
    values.houseLivingSpaces,
  ]);

  return (
    <UberSection title="YOUR HOME">
      <div ref={residence}>
        <FadeIn>
          <Section title="Please tell us about your home*">
            <Field
              name="residence"
              component={SelectableOption}
              value="STUDIO"
              type="radio"
              label="Studio"
              id="studio"
            />
            <Field
              name="residence"
              component={SelectableOption}
              value="UNIT"
              type="radio"
              label="Unit"
              id="unit"
            />
            <Field
              name="residence"
              component={SelectableOption}
              value="HOUSE"
              type="radio"
              label="House"
              id="house"
            />
          </Section>
        </FadeIn>
      </div>
      <div ref={unitInfo}>{values.residence === "UNIT" && <UnitInfo />}</div>
      <div ref={houseInfo}>{values.residence === "HOUSE" && <HouseInfo />}</div>
      <div ref={pets}>
        <FadeIn>
          <Section title="Are there any pets in the home?*">
            <Field
              name="pets"
              component={SelectableOption}
              value="YES"
              type="radio"
              label="YES WE HAVE PETS"
              id="pets-yes"
              onClick={() => scrollToSection(petsInfo)}
            />
            <Field
              name="pets"
              component={SelectableOption}
              value="NO"
              type="radio"
              label="NO PETS"
              id="pets-no"
              onClick={() => scrollToSection(smokers)}
            />
          </Section>
        </FadeIn>
      </div>
      <div ref={petsInfo}>
        {values.pets === "YES" && (
          <FadeIn>
            <Section>
              <Div>
                <p>Total number of dogs:</p>
                <Field
                  name="dogs"
                  component={AmountButton}
                  type="button"
                  id="dogs"
                  label=""
                  amountFloor={0}
                  amountCeiling={10}
                  wrapperWidth="20rem"
                  buttonSize="8.5rem"
                />
              </Div>
              <Div>
                <p>Total number of cats:</p>
                <Field
                  name="cats"
                  component={AmountButton}
                  type="button"
                  id="cats"
                  label=""
                  amountFloor={0}
                  amountCeiling={10}
                  wrapperWidth="20rem"
                  buttonSize="8.5rem"
                />
              </Div>
            </Section>
          </FadeIn>
        )}
      </div>
      <div ref={smokers}>
        <FadeIn>
          <Section title="Do any members of the household smoke indoors?*">
            <Field
              name="smokers"
              component={SelectableOption}
              value="YES"
              type="radio"
              label="YES"
              id="smoke-yes"
            />
            <Field
              name="smokers"
              component={SelectableOption}
              value="NO"
              type="radio"
              label="NO"
              id="smoke-no"
            />
          </Section>
        </FadeIn>
      </div>
      <div ref={lastProfClean}>
        <FadeIn>
          <Section title="When was your last proffessional clean?*">
            <Field name="lastProfClean" component="select">
              <option value="">Select an option</option>
              <option value="Last Week">Last Week</option>
              <option value="2 - 3 Weeks Ago">2 - 3 Weeks Ago</option>
              <option value="Over a Month Ago">Over a Month Ago</option>
              <option value="Never Professionally Cleaned">
                Never Professionally Cleaned
              </option>
            </Field>
            {touched.lastProfClean && errors.lastProfClean && (
              <ErrorMessage>{errors.lastProfClean}</ErrorMessage>
            )}
            <br />
          </Section>
        </FadeIn>
      </div>
      <div>
        <FadeIn>
          <HouseCondition />
        </FadeIn>
      </div>
    </UberSection>
  );
};

export default connect(Page3);
