import React, { useEffect, useState } from "react";
import { FieldArray, connect, getIn } from "formik";
import styled from "styled-components";

import { FaBroom, FaBed, FaBlind } from "react-icons/fa";

import { camel2Title } from "../../utilities/utilities";

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: column;
  }
`;

const Div = styled.div`
  padding: 0.75rem;
  color: #666;
  border-bottom: #f3f3f3 solid 1rem;
  marginbottom: -1rem;
`;

const InnerDiv = styled.div`
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1.5rem;
`;

const H5 = styled.h5`
  padding-top: 0.25rem;
`;

const P = styled.p`
  font-size: 0.83rem;
`;

const Item = styled.div`
  line-height: 1.5rem;
  font-size: 0.83rem;
  margin-left: 1rem;
`;

const SidePanel = (props) => {
  const values = getIn(props.formik.values);
  const setFieldValue = getIn(props.formik.setFieldValue);

  const [plnStartDate, setPlnStartDate] = useState(null);
  const [plnEndDate, setPlnEndDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    Object.entries(values).map((item) => {
      if (item[1] === false) {
        setFieldValue(item[0], "");
      }
      if (item[1] === "none") setFieldValue(item[0], "");
      return null;
    });
    if (values.planStartDate) {
      let formattedDate = values.planStartDate.toString();
      formattedDate = formattedDate.split(" ").slice(0, 4).join(" ");
      setPlnStartDate(formattedDate);
    }
    if (values.planEndDate) {
      let formattedDate = values.planEndDate.toString();
      formattedDate = formattedDate.split(" ").slice(0, 4).join(" ");
      setPlnEndDate(formattedDate);
    }
    if (values.endDate) {
      let formattedDate = values.endDate.toString();
      formattedDate = formattedDate.split(" ").slice(0, 4).join(" ");
      setEndDate(formattedDate);
    }
  }, [values, setFieldValue, plnStartDate, plnEndDate, endDate]);

  return (
    <Container>
      <Div>
        <InnerDiv>
          <FaBroom
            style={{
              color: "DarkCyan",
              fontSize: "2rem",
              padding: "1rem 0 0 0",
            }}
          />
          <H5>THERE WHEN YOU NEED US</H5>
          <P>
            Reduce unnecessary stress and anxiety about the constant up-keep of
            your home and never worry about how or when you’ll get all the
            cleaning and laundry chores done.
          </P>
        </InnerDiv>
        <InnerDiv>
          <FaBed
            style={{
              color: "DarkCyan",
              fontSize: "2rem",
              padding: "1rem 0 0 0",
            }}
          />
          <H5>LOVE YOUR HOME AGAIN</H5>
          <P>
            Love spending time in your home again – on your own, and with family
            or friends
          </P>
        </InnerDiv>
        <InnerDiv>
          <FaBlind
            style={{
              color: "DarkCyan",
              fontSize: "2rem",
              padding: "1rem 0 0 0",
            }}
          />
          <H5>DEDICATED TEAM OF PROFESSIONALS</H5>
          <P>
            Relax knowing that your home is being looked after by understanding
            and reliable professionals
          </P>
        </InnerDiv>
      </Div>
      <Div>
        <FieldArray name={values}>
          {() => (
            <React.Fragment>
              <div style={{ textAlign: "center", padding: "1rem .5rem" }}>
                <h2 style={{ color: "DarkCyan" }}>BOOKING SUMMARY</h2>
                <hr />
              </div>
              {Object.entries(values).map((item) => {
                if (item[0] === "participantDOB") {
                  return null;
                }
                if (item[0] === "participantFName") {
                  return null;
                }
                if (item[0] === "participantLName") {
                  return null;
                }
                if (item[0] === "participantEmail") {
                  return null;
                }
                if (item[0] === "participantPhone") {
                  return null;
                }
                if (item[0] === "participantMobile") {
                  return null;
                }
                if (item[0] === "budgetAmount") {
                  return null;
                }
                if (item[0] === "participantNDISRef") {
                  return null;
                }
                if (item[0] === "streetNumber") {
                  return null;
                }
                if (item[0] === "streetName") {
                  return null;
                }
                if (item[0] === "citySuburb") {
                  return null;
                }
                if (item[0] === "state") {
                  return null;
                }
                if (item[0] === "postCode") {
                  return null;
                }
                if (item[0] === "nomineeFName") {
                  return null;
                }
                if (item[0] === "nomineeLName") {
                  return null;
                }
                if (item[0] === "nomineePhone") {
                  return null;
                }
                if (item[0] === "nomineeEmail") {
                  return null;
                }
                if (item[0] === "preferredContactMethod") {
                  return null;
                }
                if (item[0] === "hasNominee") {
                  return null;
                }
                if (item[0] === "planManagement") {
                  return null;
                }
                if (item[0] === "planStartDate") {
                  return null;
                }
                if (item[0] === "planEndDate") {
                  return null;
                }
                if (item[0] === "parkingDetails") {
                  return null;
                }
                if (item[0] === "referredBy") {
                  return null;
                }
                if (item[0] === "planManagerCompany") {
                  return null;
                }
                if (item[0] === "planManagerFName") {
                  return null;
                }
                if (item[0] === "planManagerLName") {
                  return null;
                }
                if (item[0] === "planManagerPhone") {
                  return null;
                }
                if (item[0] === "planManagerEmail") {
                  return null;
                }
                if (item[0] === "endDate" && item[1] !== "" && item[1] !== []) {
                  return (
                    <Item key={item[0]}>
                      {camel2Title(item[0]) + ": "}
                      <div
                        style={{
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                      >
                        {endDate}
                      </div>
                    </Item>
                  );
                }
                if (item[0] === "planStartDate" && item[1] !== "") {
                  return (
                    <Item key={item[0]}>
                      {camel2Title(item[0]) + ": "}
                      <div
                        style={{
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                      >
                        {plnStartDate}
                      </div>
                    </Item>
                  );
                }
                if (item[0] === "planEndDate" && item[1] !== "") {
                  return (
                    <Item key={item[0]}>
                      {camel2Title(item[0]) + ": "}
                      <div
                        style={{
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                      >
                        {plnEndDate}
                      </div>
                    </Item>
                  );
                }
                /* if (item[0] === "daysAndTimes" && item[1] !== "") {
                  return (
                    <Item key={item[0]}>
                      {camel2Title(item[0]) + ": "}
                      <div
                        style={{
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                      >
                        {item[1].join(" | ")}
                      </div>
                    </Item>
                  );
                } */
                if (item[1] === true) {
                  return (
                    <Item key={item[0]}>
                      {camel2Title(item[0]) + ": "}
                      <div
                        style={{
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                      >
                        <span>YES</span>
                      </div>
                    </Item>
                  );
                }
                if (
                  item[1] !== "none" &&
                  item[1] !== "" &&
                  item[1] !== false &&
                  item[1] !== []
                ) {
                  return (
                    <Item key={item[0]}>
                      {camel2Title(item[0]) + ": "}
                      <div
                        style={{
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                      >
                        {item[1]}
                      </div>
                    </Item>
                  );
                } else return null;
              })}
            </React.Fragment>
          )}
        </FieldArray>
      </Div>
    </Container>
  );
};

export default connect(SidePanel);
