import React from "react";
import styled from "styled-components/macro";

// ${tw`flex bg-red-100 border border-red-400 text-red-700 px-4 py-2 mx-4 rounded relative`};
const ErrorWrapper = styled.div`
  display: flex;
  width: 100vw;
  font-size: 0.83rem;
  padding: 0 0 0 1.5rem;
  color: #ed1968;
`;

//Convert camelCase to titles
export const camel2Title = (camelCase) =>
  camelCase
    .replace(/[0-9]{2,}/g, (match) => ` ${match} `)
    .replace(/[^A-Z0-9][A-Z]/g, (match) => `${match[0]} ${match[1]}`)
    .replace(
      /[A-Z][A-Z][^A-Z0-9]/g,
      (match) => `${match[0]} ${match[1]}${match[2]}`
    )
    .replace(/[ ]{2,}/g, (match) => " ")
    .replace(/\s./g, (match) => match.toUpperCase())
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

/* function for determining scroll amount after click */
export const scrollToNext = (amount) =>
  setTimeout(() =>
    window.scrollTo({
      top: amount,
      left: 0,
      behavior: "smooth",
    })
  );

export const scrollToSection = (e) => {
  return setTimeout(() => {
    e.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, 300);
};

export const ErrorMessage = (props) => (
  <ErrorWrapper>{props.children}</ErrorWrapper>
);

export const errorMsgHandler = (
  touched,
  errors,
  submittedWithErrors = "submittedWithErrors"
) => {
  return (
    <>
      {(touched && errors && <ErrorMessage>{errors}</ErrorMessage>) ||
        (submittedWithErrors && <ErrorMessage>{errors}</ErrorMessage>)}
    </>
  );
};

const errorMessageHandler = (errors) => {
  if (errors) {
    return (
      <>
        <br />
        <ErrorWrapper>{errors}</ErrorWrapper>
      </>
    );
  }
};

export default errorMessageHandler;
