import React from "react";
import { getIn, connect, useField } from "formik";
import "react-datepicker/dist/react-datepicker.css";

import styled, { css } from "styled-components/macro";

import DatePicker from "react-datepicker";
import "../../styles/customStyles.css";

const today = new Date();
// let hoursDiff = today.getHours() - today.getTimezoneOffset() / 60;
// let minutesDiff = (today.getHours() - today.getTimezoneOffset()) % 60;
// today.setHours(hoursDiff);
// today.setMinutes(minutesDiff);

const dobMinDate = new Date(1910, 1, 1);

const Input = styled.input`
  display: block;
  border: 1px solid LightGray;
  width: 10rem;
  text-align: center;
  padding: 0.75rem;
  margin: 1.5rem;
  flex: 0 0 auto;
  font: 400 1rem Open Sans;
  &:hover {
    background: #f9f9f9;
  }
  ${(props) =>
    props.active &&
    css`
      border: 2px solid #ed1968;
    `}
`;

const DatepickerField = ({ ...props }) => {
  const setFieldValue = getIn(props.formik.setFieldValue);
  const handleBlur = getIn(props.formik.handleBlur);
  const [field] = useField(props);

  return (
    <>
      <DatePicker
        name={field.name}
        active={field.value}
        dateFormat="dd/MM/yyyy"
        selected={field.value}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        onBlur={handleBlur}
        minDate={
          field.name === "participantDOB" || field.name === "planStartDate"
            ? dobMinDate
            : today
        }
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        placeholderText="Please pick a date"
        customInput={<Input type="text" id={field.name} name={field.name} />}
      />
    </>
  );
};

export default connect(DatepickerField);
