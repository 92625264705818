import React, { useState, useEffect } from "react";
import { connect, getIn } from "formik";
import Section from "../../containers/Section";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  border: 1px solid lightgray;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin: 1rem;
  padding: 1rem;
`;

const ContainerWrapper = styled.div`
    flex-direction: row;
    flex-wrap; wrap;
`;

const Button = styled.button`
  border-style: none;
  background: none;
  outline: none;
  padding: 5px;
  color: ${(props) => (props.active === true ? "white" : "#666")};
`;

const Div = styled.div`
  text-align: center;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${(props) => (props.active === true ? "#ed1968" : "white")};
  padding: 10px;
`;

const DivTop = styled.div`
  text-align: center;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: ${(props) => (props.active === true ? "#ed1968" : "white")};
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  padding: 10px;
`;

const DivBottom = styled.div`
  text-align: center;
  border: 1px solid lightgray;
  background-color: ${(props) => (props.active === true ? "#ed1968" : "white")};
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  padding: 10px;
`;

// const P = styled.p`
//   flex: 3;
//   text-align: center;
// `;

const MChoiceButton = (props) => (
    <Div active={props.active}>
        <Button active={props.active} type="button" onClick={props.clicked}>
            {props.value}
        </Button>
    </Div>
);

const MChoiceButtonTop = (props) => (
    <DivTop active={props.active}>
        <Button active={props.active} type="button" onClick={props.clicked}>
            {props.value}
        </Button>
    </DivTop>
);

const MChoiceButtonBottom = (props) => (
    <DivBottom active={props.active}>
        <Button active={props.active} type="button" onClick={props.clicked}>
            {props.value}
        </Button>
    </DivBottom>
);

const HouseCondition = (props) => {
    const setFieldValue = getIn(props.formik.setFieldValue);

    const [houseConditions, setHouseConditions] = useState("");
    const [squalor, setSqualor] = useState(false);
    const [hoarding, setHoarding] = useState(false);
    const [animalWaste, setAnimalWaste] = useState(false);
    const [cluttered, setCluttered] = useState(false);
    const [wellMaintained, setWellMaintained] = useState(false);
    const [mould, setMould] = useState(false);

    const stateHandler = (value, state, setState) => {
        setState(!state);
        const strippedValue = value.replace(" | ", "");
        if (houseConditions.length === 0) {
            setHouseConditions(value.replace(" | ", ""));
            return;
        }
        if (houseConditions.includes(value)) {
            setHouseConditions(houseConditions.replace(value, ""));
        } else if (houseConditions.includes(strippedValue)) {
            setHouseConditions(houseConditions.replace(strippedValue, ""));
        } else {
            setHouseConditions(houseConditions + value);
        }
    };

    useEffect(() => {
        setFieldValue("houseConditions", houseConditions);
        if (houseConditions.length === 0) {
            setHouseConditions("");
        }
    }, [houseConditions, setFieldValue]);

    return (
        <Section
            title="Current House Condition"
            info="Please choose all that apply."
        >
            <Container>
                <ContainerWrapper>
                    <MChoiceButtonTop
                        name="squalor"
                        id="squalor"
                        value="Squalor (excessive filth/mould & garbage present)"
                        clicked={() =>
                            stateHandler(" | Squalor", squalor, setSqualor)
                        }
                        active={squalor}
                    />
                    <MChoiceButton
                        name="hoarding"
                        id="hoarding"
                        value="Hoarding (excess rubbish/household items overtaking house)"
                        clicked={() => stateHandler(" | Hoarding", hoarding, setHoarding)}
                        active={hoarding}
                    />
                    <MChoiceButton
                        name="animalWaste"
                        id="animalWaste"
                        value="Animal Waste present"
                        clicked={() => stateHandler(" | Animal Waste", animalWaste, setAnimalWaste)}
                        active={animalWaste}
                    />
                    <MChoiceButton
                        name="cluttered"
                        id="cluttered"
                        value="Cluttered but not filthy (organization/housekeeping needed)"
                        clicked={() => stateHandler(" | Cluttered", cluttered, setCluttered)}
                        active={cluttered}
                    />
                    <MChoiceButton
                        name="wellMaintained"
                        id="wellMaintained"
                        value="Well maintained (reasonable amount of dust/day-to-day grime)"
                        clicked={() => stateHandler(" | Well Maintained", wellMaintained, setWellMaintained)}
                        active={wellMaintained}
                    />
                    <MChoiceButtonBottom
                        name="mould"
                        id="mould"
                        value="Mould present"
                        clicked={() =>
                            stateHandler(" | Mould", mould, setMould)
                        }
                        active={mould}
                    />
                </ContainerWrapper>
            </Container>
        </Section>
    );
};

export default connect(HouseCondition);
