import React from "react";

import { Field, connect, getIn } from "formik";
import Section from "../containers/Section";
import FadeIn from "react-fade-in";
import "../styles/customStyles.css";
import { Label, Input } from "../styles/InputStyles";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ErrorMessage } from "../utilities/utilities";

const PlanManager = (props) => {
  const touched = getIn(props.formik.touched);
  const errors = getIn(props.formik.errors);
  const setFieldValue = getIn(props.formik.setFieldValue);
  return (
    <>
      <FadeIn>
        <Section>
          <Field name="planManagerCompany" id="planManagerCompany">
            {({ field }) => (
              <>
                <Label htmlFor={field.id}>
                  Plan Manager Company/Business Name*:
                </Label>
                <Input {...field} />
              </>
            )}
          </Field>
          {touched.planManagerCompany && errors.planManagerCompany && (
            <ErrorMessage>{errors.planManagerCompany}</ErrorMessage>
          )}
          <Field name="planManagerFName" id="planManagerFName">
            {({ field }) => (
              <>
                <Label htmlFor={field.id}>Plan Manager First Name:</Label>
                <Input {...field} />
              </>
            )}
          </Field>
          <Field name="planManagerLName" id="planManagerLName">
            {({ field }) => (
              <>
                <Label htmlFor={field.id}>Plan Manager Last Name:</Label>
                <Input {...field} />
              </>
            )}
          </Field>
          <Field name="planManagerPhone" id="planManagerPhone">
            {({ field }) => (
              <>
                <Label htmlFor={field.id}>Plan Manager Phone Number:</Label>
                <PhoneInput
                  countries={["AU"]}
                  defaultCountry="AU"
                  value={field.value}
                  onChange={(phoneNumber) => {
                    setFieldValue(field.name, phoneNumber);
                    return !null ? phoneNumber : null;
                  }}
                  name="planManagerPhone"
                />
              </>
            )}
          </Field>
          <br />
          <br />
          <br />
          <Field name="planManagerEmail" id="planManagerEmail">
            {({ field }) => (
              <>
                <Label htmlFor={field.id}>
                  Plan Manager Email Address (for invoicing)*:
                </Label>
                <Input {...field} />
              </>
            )}
          </Field>
          {touched.planManagerEmail && errors.planManagerEmail && (
            <ErrorMessage>{errors.planManagerEmail}</ErrorMessage>
          )}
        </Section>
      </FadeIn>
    </>
  );
};

export default connect(PlanManager);
