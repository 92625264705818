import React, { useState } from "react";

import { Input } from "../../styles/ButtonStyles";
import styled, { css } from "styled-components/macro";
import "../customIcons/customIcons.css";

const Label = styled.label`
  display: block;
  border: 1px solid LightGray;
  width: 6rem;
  height: 6rem;
  text-align: center;
  padding: 1.5rem 0.75rem 0;
  margin: 0 1rem;
  flex: 0 0 auto;
  border-radius: 1rem;
  &:hover {
    background: #f9f9f9;
  }
  ${(props) =>
    props.active &&
    css`
      border: 2px solid #ed1968;
    `}
`;

const Div = styled.div`
  padding-bottom: 10px;
  flex: 0.3;
`;

const ExtrasHeader = ({
  field,
  form: { values, setFieldValue, handleChange, handleBlur },
  id,
  icon,
  label,
}) => {
  const [isActive, setIActive] = useState(false);
  const handleInputPress = () => {
    setIActive(!isActive);
    setFieldValue(field.name, !field.value);
  };

  return (
    <Div>
      <p>{label}</p>
      <Label htmlFor={id} active={isActive}>
        <i className={icon} />
        <Input
          id={id}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          onBlur={handleBlur}
          type="checkbox"
          onClick={handleInputPress}
        />
      </Label>
    </Div>
  );
};

export default ExtrasHeader;
